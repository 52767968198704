import { debounce, isElementInViewport } from '../utils/helpers'

const { ajaxurl } = window
let isLoading = false
let hasMorePostsToLoad = true
let pageCount = 1
const whitepaperList = document.getElementById('whitepaper_list_holder')
// const delay = 0
const disableScrollListenerEventName = 'disableScrollListener'
const DisableScrollListenerEvent = new CustomEvent(disableScrollListenerEventName)

const parseHTML = (str) => {
  const tmp = document.createElement('span')
  tmp.innerHTML = str
  return Array.from(tmp.children)
}

const renderResponseHtml = (container, res) => {
  if (!container || typeof container === 'undefined') return

  if (typeof res === 'object') {
    const html = parseHTML(res)
    html.map((child) => {
      child.classList.add('transition-visible-element', 'transition-visible-element--active')
      container.appendChild(child)
      return setTimeout(() => child.classList.remove('transition-visible-element--active'), 200)
    })
  }
}
const doAjaxLoad = (postsContainer, totalElements) => {
  isLoading = true
  const request = new XMLHttpRequest()
  const formData = new FormData()
  formData.append('action', 'ajaxLoadMoreWhitepapers')
  formData.append('page', pageCount += 1)
  formData.append('total_elements', totalElements)

  request.onreadystatechange = () => {
    if (request.readyState === 1) {
      // start loader here
    }
    // it's done
    if (request.readyState === 4) {
      // remove loader here
      // it's successful
      if (request.status === 200) {
        const res = JSON.parse(request.responseText)

        isLoading = false
        hasMorePostsToLoad = res.more_posts_to_load
        renderResponseHtml(postsContainer, res.responseHtml)
      }
    }
  }

  request.open('POST', ajaxurl, true)
  request.send(formData)
}

const LoadMorePosts = debounce(() => {
  if (isLoading) return
  if (!hasMorePostsToLoad) {
    window.dispatchEvent(DisableScrollListenerEvent)
    return
  }
  const container = document.getElementById('whitepaper_list_holder')
  if (isElementInViewport(container.lastElementChild, (container.lastElementChild.getBoundingClientRect().height / 2))) {
    doAjaxLoad(container, container.children.length)
  }
}, 25)

function LoadMore() {
  if (!whitepaperList || !hasMorePostsToLoad) return
  window.addEventListener('scroll', () => LoadMorePosts())
  window.addEventListener(disableScrollListenerEventName, () => window.removeEventListener('scroll', () => LoadMorePosts(), false))
}
export default LoadMore
