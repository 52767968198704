import $ from 'jquery';

const FormHandler = () => {
    const btn = document.querySelectorAll('.navigation-bar_contact-btn');
    const form = document.querySelector('.contact-form');
    const closeBtn = document.querySelector('.contact-form__close-btn');
    const body = document.querySelector('body');
    const contactForm = $('.js-contact-form');
    const contactFormTitle = document.querySelector('.contact-form__title');

    for( let i = 0; i < btn.length; i++ ) {
        btn[i].addEventListener('click', (e) => {
            if (form.classList.contains('is-open')) {
                form.classList.remove('is-open');
            }
            else {
                form.classList.add('is-open');
                body.style.overflow = 'hidden';
                form.style.overflow = 'scroll';
            }
        });
    }

    closeBtn.addEventListener('click', (e) => {
        e.preventDefault();
        form.classList.remove('is-open');
        body.style.overflow = 'scroll';
    });

    // contactForm.on('submit', function(e) {
    //     e.preventDefault();
    //     $.ajax({
    //         type: 'POST',
    //         url: AJAX_URL,
    //         data: {
    //             action: 'send_contact_form',
    //             formName: $(contactForm).find('.contact-form__name').val(),
    //             formCompany: $(contactForm).find('.contact-form__company').val(),
    //             formSubject: $(contactForm).find('.contact-form__subject').val(),
    //             formMessage: $(contactForm).find('.contact-form__message').val(),
    //             formEmail: $(contactForm).find('.contact-form__email').val(),
    //         },
    //         success: function(result) {
    //             contactFormTitle.innerText = 'Tack för ditt meddelande!';
    //         },
    //         error: function(jqxhr, status, exception) {
    //             console.log(exception);
    //         }
    //     });
    // });
};

export default FormHandler;
