// import 'babel-polyfill'
import "picturefill";
import smoothscroll from "smoothscroll-polyfill";

import "./utils/polyfills";

import NavigationBarHandler from "./components/NavigationBarHandler";
import FormHandler from "./components/FormHandler";
import AccordionHandler from "./components/AccordionHandler";
import AjaxLoadMoreWhitepapers from "./components/AjaxLoadMoreWhitepapers";
import AnchorScroll from "./components/AnchorScroll";
import SlidersHandler from "./components/SlidersHandler";
// import cookiebar from './components/cookiebar'
/*
  ** kick off the polyfill!
  For internet safari
 */
smoothscroll.polyfill();

const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.boxSizing = "border-box";
  target.style.height = target.offsetHeight + "px";
  target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = "none";
    target.style.removeProperty("height");
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
  }, duration);
};

const slideDown = (target, duration = 500) => {
  target.style.removeProperty("display");
  let display = window.getComputedStyle(target).display;

  if (display === "none") display = "block";

  target.style.display = display;
  let height = target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = "border-box";
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.height = height + "px";
  target.style.removeProperty("padding-top");
  target.style.removeProperty("padding-bottom");
  target.style.removeProperty("margin-top");
  target.style.removeProperty("margin-bottom");
  window.setTimeout(() => {
    target.style.removeProperty("height");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
  }, duration);
};
const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === "none") {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
};

window.slideUp = slideUp;
window.slideDown = slideDown;
window.slideToggle = slideToggle;

document.addEventListener("DOMContentLoaded", () => {
  document.createElement("picture");

  NavigationBarHandler();
  FormHandler();
  AccordionHandler();
  AjaxLoadMoreWhitepapers();
  AnchorScroll();
  SlidersHandler();
});
