export const debounce = (func, wait, immediate) => {
  let timeout
  return () => {
    const context = this
    const args = arguments

    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const isElementInViewport = (element, offset = 0) => {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
        rect.left >= 0 &&
        (rect.bottom - offset) <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  )
}
