


(function() {

   /**
  * Event polyfill
  */
  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }
   /**
    * if IE, do this
    */
  if(window.document.documentMode) {
    window.Event = CustomEvent;
  }
  window.CustomEvent = CustomEvent;
    /**
  * Event polyfill
  */

/**
* requestAnimationFrame polyfill
*/
 var lastTime = 0;
 var vendors = ['ms', 'moz', 'webkit', 'o'];
 for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
     window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
     window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
                                || window[vendors[x]+'CancelRequestAnimationFrame'];
 }

 if (!window.requestAnimationFrame)
     window.requestAnimationFrame = function(callback, element) {
         var currTime = new Date().getTime();
         var timeToCall = Math.max(0, 16 - (currTime - lastTime));
         var id = window.setTimeout(function() { callback(currTime + timeToCall); },
           timeToCall);
         lastTime = currTime + timeToCall;
         return id;
     };

 if (!window.cancelAnimationFrame)
     window.cancelAnimationFrame = function(id) {
         clearTimeout(id);
     };
     /**
* requestAnimationFrame polyfill
*/
}())
