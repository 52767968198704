function AccordionHandler() {
  const accordionItems = Array.from(
    document.querySelectorAll(".accordion__item")
  );

  for (let i = 0; i < accordionItems.length; i++) {
    accordionItems[i].addEventListener("click", (e) => {
      const _this = e.currentTarget;
      _this.classList.toggle("is-open");
      accordionItems.filter((value, index, array) => {
        value != _this ? value.classList.remove("is-open") : false;
      });
    });
  }

  /**
   * Expandable blocks in Addsouced-page-template
   */
  const expandableBlocks = Array.from(
    document.querySelectorAll(".InfoSection-expandable-blocks")
  );

  if (expandableBlocks && expandableBlocks.length > 0) {
    for (let expandableBlock of expandableBlocks) {
      const blocks = Array.from(
        expandableBlock.querySelectorAll(".InfoSection-expandable-blocks-block")
      );

      if (blocks || blocks.length > 1) {
        for (let block of blocks) {
          const blockTrigger = block.querySelector(
            ".InfoSection-expandable-blocks-block-header"
          );
          const blockContent = block.querySelector(
            ".InfoSection-expandable-blocks-block-content"
          );
          if (!blockContent || !blockTrigger) {
            return false;
          }
          slideUp(blockContent);
          let blockOpenState = false;
          blockTrigger.addEventListener(
            "click",
            function () {
              const icon = blockTrigger.querySelector(
                ".InfoSection-expandable-blocks-block-header-symbol"
              );
              const openIcon = icon.getAttribute("data-closed");
              const closeIcon = icon.getAttribute("data-open");
              blockOpenState = !blockOpenState;
              slideToggle(blockContent);
              icon.textContent = blockOpenState ? closeIcon : openIcon;
            },
            false
          );
        }
      }
    }
  }
}

export default AccordionHandler;
