const NavigationBarHandler = () => {
    let refOffset = 0;
    const bannerHeight = 66;
    const bannerWrapper = document.querySelector('.navigation-bar');
    const mobileOverlay = document.querySelector('.js-mobile-overlay');
    const menuButton = document.querySelector('.js-open-menu');

    menuButton.addEventListener('click', (e) => {
        const _this = e.currentTarget;

        if ( _this.classList.contains('is-active') ) {
            mobileOverlay.classList.remove('is-open');
            _this.classList.remove('is-active');
        } else {
            mobileOverlay.classList.add('is-open');
            _this.classList.add('is-active');
        }
    });

    const handler = () => {
        if ( mobileOverlay.classList.contains('is-open') === false )
        {
            const newOffset = window.scrollY || window.pageYOffset;

            if (newOffset > bannerHeight) {
                if (newOffset > refOffset) {
                    bannerWrapper.classList.remove('animateIn');
                    bannerWrapper.classList.add('animateOut');
                }
                else {
                    bannerWrapper.classList.remove('animateOut');
                    bannerWrapper.classList.add('animateIn');
                }
                refOffset = newOffset;
            }
        }
    };

    window.addEventListener('scroll', handler, false);
};

export default NavigationBarHandler;
