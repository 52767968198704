function scrollAnchors(e, respond = null) {
    const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

    e.preventDefault();
    var targetID = respond
        ? respond.getAttribute("href")
        : this.getAttribute("href");
        console.log(targetID)

    const targetAnchor = document.querySelector(targetID);
    if (!targetAnchor) return;
    const originalTop = distanceToTop(targetAnchor);

    //   window.scrollBy({ top: originalTop, left: 0 });
    var isSmoothScrollSupported =
        "scrollBehavior" in document.documentElement.style;

    var options = {
        behavior: "smooth",
        left: 0,
        top: originalTop
    };
    if (isSmoothScrollSupported) {
        window.scrollBy(options);
    } else if (window.jQuery && jQuery.scrollTo) {
        $(window).scrollTo(options.top, options.left);
    } else {
      /**
       * Polyfill fixes this
       */
        window.scrollBy(options);
    }
    const checkIfDone = setInterval(function() {
        const atBottom =
            window.innerHeight + window.pageYOffset >=
            document.body.offsetHeight - 2;
        if (distanceToTop(targetAnchor) === 0 || atBottom) {
            targetAnchor.tabIndex = "-1";
            window.history.pushState("", "", targetID);
            clearInterval(checkIfDone);
        }
    }, 100);
}
function AnchorScroll() {
  var links = Array.from(document.getElementsByTagName("a"))
  for (let link of links) {
    if (
      link.href &&
      link.href.indexOf("#") != -1 &&
      (link.pathname == location.pathname ||
          "/" + link.pathname == location.pathname) &&
      link.search == location.search
  ) {
      link.addEventListener("click", function(e) {
        scrollAnchors(e, link)
      });
  }
}
}

export default AnchorScroll