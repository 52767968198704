import Flickity from 'flickity'



function SlidersHandler() {
const TextContentCarousels = Array.from(document.querySelectorAll('.TextContentCarousel'))
  if (!TextContentCarousels) {
    return false
  }

  for (let carouselHolder of TextContentCarousels) {
    const carousel = carouselHolder.querySelector('.TextContentCarousel-carousel')
    var flkty = new Flickity( carousel, {
      // options
      cellCelector: 'TextContentCarousel-column',
      cellAlign: 'left',
      contain: true,
      pageDots: false,
      prevNextButtons: false
    });

    const prevArrow = carouselHolder.querySelector('.TextContentCarousel-prev')
    const nextArrow = carouselHolder.querySelector('.TextContentCarousel-next')
    let mql = window.matchMedia('(max-width: 800px)');

    prevArrow.disabled = true
    prevArrow.addEventListener('click', function(e) {
      e.preventDefault
      if (flkty.selectedIndex !== 0) {
        flkty.previous()
      }
    })
    nextArrow.addEventListener('click', function(e) {
      e.preventDefault
      if (flkty.selectedIndex < flkty.cells.length) {
        flkty.next()
      }
    })
    flkty.on( 'select', function( index ) {
      if(index === 0) {
        prevArrow.disabled = true
      } else {
        prevArrow.disabled = false
      }
      const disabledAtIndex = mql.matches ? 3 : flkty.cells.length - 3
      if(index === disabledAtIndex) {
        // nextArrow.classList.add()
        nextArrow.disabled = true
      } else {
        nextArrow.disabled = false
      }
    });

  }
}

export default SlidersHandler